.section-b {
  background-color: #e9e9e9;
}
.benefTit {
  color: #2d2d2d;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.benefTxt {
  color: #2d2d2d;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 48px;
}
.benefList {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

@media screen and (min-width: 768px) {
  .benefList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    justify-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 1440px) {
  .benefList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    justify-items: center;
    justify-content: center;
  }

  .benefItem {
    padding: 20px;
    text-align: center;
    background-color: #e9e9e9;
    /* margin: 0 auto;
    display: block; */
  }
  .benef-img {
    margin-top: 48px;
  }
}

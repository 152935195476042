.catalog {
  padding-top: 50px;
  padding-bottom: 50px;
}
.catalog-subtitle {
  color: #2d2d2d;
  text-align: center;
  font-size: 32px;
  margin-bottom: 12px;
}
.catalog-link {
  color: #ec7f00;
  font-size: 32px;
  text-transform: uppercase;
}
.catalog-txt {
  display: block;
  margin: 0 auto;
  color: #2d2d2d;
  text-align: center;
  font-size: 18px;
  width: 285px;
  margin-bottom: 50px;
}
.catalog-wrap {
  margin: 0 auto;
}
.catalog-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 100px;
  margin-bottom: 30px;
}
.img-title {
  color: #2d2d2d;
  text-align: center;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 25px;
}
.catalog-item {
  position: relative;
  cursor: pointer;
}

.catalog-img {
  box-shadow: 10px 10px #ec7f00;
  transition: 0.5s ease;
  backface-visibility: hidden;
  opacity: 1;
  cursor: pointer;
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background-color: #ec7f00;
  border-top-right-radius: 30%;
  border-bottom-left-radius: 30%;
}

.catalog-item:hover .catalog-img {
  opacity: 0.3;
}

.catalog-item:hover .middle {
  opacity: 1;
}

.text {
  /* background-color: #ec7f00;
  border-radius: 20%; */
  color: white;
  font-size: 18px;
  padding: 16px 16px;
}

@media screen and (min-width: 768px) {
  .catalog-subtitle {
    font-size: 34px;
  }
  .catalog-link {
    font-size: 34px;
  }
  .catalog-txt {
    font-size: 20px;
    width: 325px;
  }
}

@media screen and (min-width: 1440px) {
  .catalog-subtitle {
    font-size: 36px;
  }
  .catalog-link {
    font-size: 36px;
  }
  .catalog-txt {
    font-size: 20px;
    width: 425px;
  }
}

.about {
  padding: 40px 0;
}
.about-subtitle {
  color: #000;
  font-size: 22px;
  font-style: italic;
  text-align: center;
  margin-bottom: 25px;
}
.about-txt {
  color: #000;
  font-size: 18px;
  padding: 0 25px;
  text-align: center;
}
.benef-img {
  width: 300px;
}

@media screen and (min-width: 768px) {
  .about-subtitle {
    font-size: 24px;
  }
  .about-txt {
    font-size: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .benef-img {
    width: 340px;
  }
}

@import url(./reset.css);
@import url(./base.css);
@import url(./about.css);
@import url(./catalog.css);
@import url(./header.css);
@import url(./hero.css);
@import url(./contacts.css);
@import url(./beneff.css);
@import url(./footer.css);
@import url(./home-table.css);
@import url(./button-up.css);
@import url(./submit.css);
@import url(./privacy-policy.css);

.loc-title {
  color: #2d2d2d;
  font-size: 32px;
  margin-bottom: 14px;
  display: flex;
}
.contacts {
  padding: 20px 0;
}
.icon-loc {
  fill: #ec7f00;
}
.map {
  box-shadow: 10px 10px #ec7f00;
  width: 300px;
  height: 250px;
}
.contacts-wrap {
  display: flex;
  flex-direction: column;
  gap: 36px;
  justify-content: center;
  align-items: center;
  padding-bottom: 44px;
}
.contacts-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: flex-start;
  font-size: 20px;
}
.icon-contacts {
  fill: #ec7f00;
  margin-right: 8px;
}
.contacts-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .contacts-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0 30px;
  }
  .map {
    width: 350px;
    height: 250px;
  }
  .contacts-list {
    gap: 14px;
    font-size: 22px;
  }
}
@media screen and (min-width: 1440px) {
  .map {
    box-shadow: 10px 10px 4px 2px #ec7e00;
    width: 600px;
    height: 450px;
  }
  .contacts-list {
    gap: 16px;
    font-size: 24px;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
    visibility 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
.backdrop.is-hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.submit-popup {
  height: auto;
  /* padding: 72px 24px 24px 24px; */
  background-color: #e5e5e5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12),
    0px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
}

.logo-side {
  display: none;
}

.submit-box {
  padding: 20px 30px;
  width: 308px;
}

.contact-form {
  width: 308px;
  /* height: auto;
  margin: auto; */
  border-radius: 4px;
}
.contact-form-input {
  font-size: 14px;
  width: 100%;
  height: 40px;
  margin-bottom: 8px;
  padding-left: 18px;
  border: 1px solid #a6a4a4;
  transition: border-color 250ms linear;
}
.contact-form-input:focus {
  outline: none;
  border-color: #2d2d2d;
}
.policy-accept-txt {
  font-size: 12px;
}
.modal-send-btn {
  display: block;
  margin: 0 auto;
}
.checkbox-field {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .logo-side {
    display: block;
    background-color: #313131;
    background-image: url('../images/bg-bricks/kisspng-stone-wall-brick-clip-art-get-brick-texture-png-pictures-5ab138695d4222\ 1\ \(Traced\).png');
    background-position: top 0 left 0;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0 40px;
  }
  .logo-pop {
    text-align: center;
    margin-top: 88px;
    margin-bottom: 250px;
    font-family: Impact;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
  }

  .submit-box {
    padding: 20px 30px;
    width: 408px;
  }

  .contact-form {
    width: 368px;
    /* height: auto;
    margin: auto; */
    border-radius: 4px;
  }
  .contact-form-input {
    font-size: 14px;
    width: 100%;
    height: 40px;
    margin-bottom: 8px;
    padding-left: 18px;
    border: 1px solid #a6a4a4;
  }
}

.modal-descr-txt {
  font-family: Impact, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.02em;
  /* margin-left: auto;
  margin-right: auto;
  margin-bottom: 14px;
  margin-top: 0; */
  color: #f2a500;
}
.descr-txt-dark {
  color: #2d2d2d;
}
.loc-txt-popup {
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
}
.form-side {
  position: relative;
}
.popup-close-btn {
  background-color: transparent;
  position: absolute;
  top: 15px;
  right: 15px;
  box-shadow: none;
  transition: box-shadow 250ms linear;
}
.popup-close-btn:hover,
.popup-close-btn:focus {
  box-shadow: 0px 0px 0px 6px rgba(236, 126, 0, 0.325);
}
/* ---------- */
.contact-form-message {
  padding-top: 12px;
}

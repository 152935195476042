.hero {
  background-color: #313131;
  background-image: url(../images/bg-bricks/hero-br-img.png);
  background-position: top -15px left 0;
  background-position: right;
  background-repeat: no-repeat;
  height: 450px;
}
.title-container {
  padding: 40px 30px;
  background-image: url('../images/bg-bricks/kisspng-stone-wall-brick-clip-art-get-brick-texture-png-pictures-5ab138695d4222\ 1\ \(Traced\).png');
  background-position: top -15px left 0;
  background-repeat: no-repeat;
  height: 450px;
}
.hero-subtitle {
  color: #fff;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 300px;
}

.hero-title {
  color: #fff;
  font-family: Impact;
  font-size: 54px;
  letter-spacing: 2px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-shadow: rgb(169, 169, 169) 1px 0 5px;
  /* width: 495px; */
  margin-top: 60px;
  margin-bottom: 30px;
}

/* -------------animation----------------- */
.slide-in-left {
  -webkit-animation: slide-in-left 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* -------------------------------- */

@media screen and (min-width: 768px) {
  .hero-subtitle {
    font-size: 20px;
    font-style: normal;
    /* width: 325px; */
  }

  .hero-title {
    font-size: 68px;
    /* width: 495px; */
    /* margin-bottom: 30px; */
  }
  .title-container {
    padding: 40px 30px;
  }
}

@media screen and (min-width: 1440px) {
  .hero-subtitle {
    width: 325px;
  }

  .hero-title {
    width: 550px;
    margin-bottom: 30px;
  }
  .title-container {
    padding: 50px 60px;
  }

  .hero {
    background-color: #313131;
    height: 650px;
    /* background-image: url('../images/bg-bricks/kisspng-stone-wall-brick-clip-art-get-brick-texture-png-pictures-5ab138695d4222\ 1\ \(Traced\).png');
    background-position: top -200px left 0;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    height: 647px; */
  }
}

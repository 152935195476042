.btn_up {
  position: fixed;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  right: 30px;
  bottom: 47px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: linear-gradient(to top, #ec7f00, rgba(220, 220, 220, 1));
  opacity: 1;
  transition: box-shadow 250ms linear, opacity 300ms linear;
  z-index: 1000;
}

.btn_up:hover {
  box-shadow: 0px 0px 0px 6px rgba(236, 126, 0, 0.325);
}

.hide-btn_up {
  opacity: 0;
  pointer-events: none;
}

.icon-btn_up {
  fill: rgb(255, 255, 255);
}
@media screen and (min-width: 1440px) {
  .btn_up {
    right: 50px;
    bottom: 50px;
    width: 45px;
    height: 45px;
  }
}

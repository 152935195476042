.header {
  background-color: #313131;
  background-image: url('../images/bg-bricks/kisspng-stone-wall-brick-clip-art-get-brick-texture-png-pictures-5ab138695d4222\ 1\ \(Traced\).png');
  background-position: top 0 left 0;
  background-repeat: no-repeat;
}

.logo {
  font-family: Impact;
  font-size: 58px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.logo-wrapp {
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 30px;
}
.accent-logo {
  color: #f2a500;
}
.logo-hd {
  color: #fff;
}

.btn {
  display: none;
}
.navigation-list {
  display: none;
}

/* ---------mobile menu-------------- */

.mobile-menu-btn {
  background-color: transparent;
  border: none;
  align-items: center;
  position: absolute;
  top: 45px;
  right: 10px;
}
.mobile-menu-icon {
  fill: #ec7f00;
}

.mobile-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* padding: 80px 40px 40px 40px; */
  background-color: #2d2d2d;
  background-image: url('../images/bg-bricks/kisspng-stone-wall-brick-clip-art-get-brick-texture-png-pictures-5ab138695d4222\ 1\ \(Traced\).png');
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 500ms linear, visibility 500ms linear;
}
.mobile-menu.is-open {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.mobile-menu-btn-close {
  position: absolute;
  top: 45px;
  right: 20px;
  background-color: transparent;
  box-shadow: none;
  transition: box-shadow 250ms linear;
}
.modal-close-btn-icon {
  fill: #ec7f00;
}
.mobile-menu-btn-close:hover,
.mobile-menu-btn-close:focus {
  box-shadow: 0px 0px 0px 6px rgba(236, 126, 0, 0.325);
}

.mobile-menu-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 100px 25px;
  gap: 30px;
}
.mobile-menu-item {
  color: #fff;
  font-size: 36px;
}
/* 
.mobile-menu-item:nth-child(-n + 2) {
  margin-bottom: 40px;
} */
.mob-contacts {
  display: flex;
  gap: 24px;
  justify-content: center;
  margin-top: 30px;
  padding-bottom: 30px;
}
.btn-mob {
  display: block;
  margin: 0 auto;
  width: 200px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #ec7f00;
  color: #fff;
  font-family: Montserrat;
  font-size: 18px;
}

/* ------------------- */

@media screen and (min-width: 768px) {
  .logo {
    font-size: 68px;
  }
  .mobile-menu-btn {
    display: none;
  }
  .mobile-menu {
    display: none;
    width: 0;
  }
  .btn {
    display: block;
    width: 200px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #ec7f00;
    color: #fff;
    font-family: Montserrat;
    font-size: 18px;
  }
  .btn-submit {
    position: absolute;
    top: 40px;
    right: 20px;
  }

  .navigation {
    margin-top: 28px;
    padding: 10px 20px;
  }
  .navigation-list {
    display: flex;
    justify-content: center;
    gap: 39px;
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media screen and (min-width: 1440px) {
  .btn {
    width: 280px;
    height: 60px;
    font-size: 20px;
  }
  .btn-submit {
    position: absolute;
    top: 40px;
    right: 60px;
  }

  .navigation {
    margin-top: 34px;
  }
  .navigation-list {
    display: flex;
    justify-content: center;
    gap: 59px;
    font-size: 24px;
  }
}

.links {
  position: relative;
  padding: 5px 5px;
}
.links::before,
.links::after {
  content: '';
  height: 14px;
  width: 14px;
  position: absolute;
  transition: all 0.35s ease;
  opacity: 0;
}
.links::before {
  content: '';
  right: -5px;
  top: 0;
  border-top: 3px solid #ec7f00;
  border-right: 3px solid #bd6500;
  transform: translate(-100%, 50%);
}

.links:after {
  content: '';
  left: -5px;
  bottom: 0;
  border-bottom: 3px solid #bd6500;
  border-left: 3px solid #ec7f00;
  transform: translate(100%, -50%);
}

.links:hover:before,
.links:hover:after {
  transform: translate(0, 0);
  opacity: 1;
}

.links:hover {
  color: #ec7f00;
}

.policy {
  padding: 50px 100px;
  background-image: url('../images/bg-bricks/kisspng-stone-wall-brick-clip-art-get-brick-texture-png-pictures-5ab138695d4222\ 1\ \(Traced\).png');
  background-repeat: no-repeat;
}

.policy-txt {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  text-align: center;
  line-height: 28px;
}
.policy-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.policy-dec {
  text-decoration: underline;
}

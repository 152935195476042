.catalog-menu,
.stellage-menu,
.interier-menu,
.bus-shells,
.bar-menu,
.hangers,
.garden-bench,
.barbeque,
.swing {
  display: none;
}

.catalog-open {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #2d2d2de3;
  backdrop-filter: blur(5px);
  z-index: 999;
  overflow: scroll;
  /* padding: 65px 50px; */
}
.catalog-menu-list {
  padding: 30px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.close-bench,
.close-swing,
.close-barbeque,
.close-bar,
.close-hangers,
.close-shells,
.close-table,
.close-stellage,
.close-inter {
  top: 20px;
  right: 30px;
  width: 40px;
  height: 40px;
}
.section-title-menu {
  padding-top: 60px;
}

body {
  color: #2d2d2d;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 auto;
}

section {
  position: relative;
  /* padding: 10px 20px; */
}

.container {
  width: 325px;
  margin: 0 auto;
  /* padding: 0 20px; */
}

.section-title {
  color: #ec7f00;
  font-family: Impact;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .container {
    width: 768px;
    /* padding: 0 32px; */
  }

  .section-title {
    font-size: 56px;
    margin-bottom: 36px;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    width: 1440px;
    /* padding: 0 24px; */
  }

  .section-title {
    font-size: 60px;
    margin-bottom: 44px;
  }
  .section {
    width: 1440;
    margin: 0 auto;
    box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.5),
      5px 0 5px -5px rgba(0, 0, 0, 0.5);
    /* box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1); */
  }
}

/* .container {
 width: 100%;
  padding: 30px 35px;
} */

/* @media screen and (max-width: 767px) {
  .container {
    min-width: 320px;
    max-width: 428px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 768px) {
  .container {
    width: 768px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }
} */

p, h1, h2, h3, h4, h5, h6, ul, li {
  margin: 0;
  padding: 0;
}

ul, ol {
  list-style: none;
}

button {
  cursor: pointer;
  border: none;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  color: #2d2d2d;
  margin: 0 auto;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

section {
  position: relative;
}

.container {
  width: 325px;
  margin: 0 auto;
}

.section-title {
  color: #ec7f00;
  text-align: center;
  margin-bottom: 30px;
  font-family: Impact;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (min-width: 768px) {
  .container {
    width: 768px;
  }

  .section-title {
    margin-bottom: 36px;
    font-size: 56px;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    width: 1440px;
  }

  .section-title {
    margin-bottom: 44px;
    font-size: 60px;
  }

  .section {
    width: 1440px;
    margin: 0 auto;
    box-shadow: -5px 0 5px -5px rgba(0, 0, 0, .5), 5px 0 5px -5px rgba(0, 0, 0, .5);
  }
}

.about {
  padding: 40px 0;
}

.about-subtitle {
  color: #000;
  text-align: center;
  margin-bottom: 25px;
  font-size: 22px;
  font-style: italic;
}

.about-txt {
  color: #000;
  text-align: center;
  padding: 0 25px;
  font-size: 18px;
}

.benef-img {
  width: 300px;
}

@media screen and (min-width: 768px) {
  .about-subtitle {
    font-size: 24px;
  }

  .about-txt {
    font-size: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .benef-img {
    width: 340px;
  }
}

.catalog {
  padding-top: 50px;
  padding-bottom: 50px;
}

.catalog-subtitle {
  color: #2d2d2d;
  text-align: center;
  margin-bottom: 12px;
  font-size: 32px;
}

.catalog-link {
  color: #ec7f00;
  text-transform: uppercase;
  font-size: 32px;
}

.catalog-txt {
  color: #2d2d2d;
  text-align: center;
  width: 285px;
  margin: 0 auto 50px;
  font-size: 18px;
  display: block;
}

.catalog-wrap {
  margin: 0 auto;
}

.catalog-list {
  flex-wrap: wrap;
  justify-content: center;
  gap: 100px;
  margin-bottom: 30px;
  display: flex;
}

.img-title {
  color: #2d2d2d;
  text-align: center;
  margin-top: 25px;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.catalog-item {
  cursor: pointer;
  position: relative;
}

.catalog-img {
  backface-visibility: hidden;
  opacity: 1;
  cursor: pointer;
  transition: all .5s;
  box-shadow: 10px 10px #ec7f00;
}

.middle {
  opacity: 0;
  text-align: center;
  background-color: #ec7f00;
  border-top-right-radius: 30%;
  border-bottom-left-radius: 30%;
  transition: all .5s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.catalog-item:hover .catalog-img {
  opacity: .3;
}

.catalog-item:hover .middle {
  opacity: 1;
}

.text {
  color: #fff;
  padding: 16px;
  font-size: 18px;
}

@media screen and (min-width: 768px) {
  .catalog-subtitle, .catalog-link {
    font-size: 34px;
  }

  .catalog-txt {
    width: 325px;
    font-size: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .catalog-subtitle, .catalog-link {
    font-size: 36px;
  }

  .catalog-txt {
    width: 425px;
    font-size: 20px;
  }
}

.header {
  background-color: #313131;
  background-image: url("kisspng-stone-wall-brick-clip-art-get-brick-texture-png-pictures-5ab138695d4222 1 (Traced).a4254cc5.png");
  background-position: 0 0;
  background-repeat: no-repeat;
}

.logo {
  font-family: Impact;
  font-size: 58px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.logo-wrapp {
  justify-content: center;
  padding-top: 30px;
  display: flex;
  position: relative;
}

.accent-logo {
  color: #f2a500;
}

.logo-hd {
  color: #fff;
}

.btn, .navigation-list {
  display: none;
}

.mobile-menu-btn {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  align-items: center;
  position: absolute;
  top: 45px;
  right: 10px;
}

.mobile-menu-icon {
  fill: #ec7f00;
}

.mobile-menu {
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  background-color: #2d2d2d;
  background-image: url("kisspng-stone-wall-brick-clip-art-get-brick-texture-png-pictures-5ab138695d4222 1 (Traced).a4254cc5.png");
  flex-direction: column;
  justify-content: space-between;
  transition: opacity .5s linear, visibility .5s linear;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.mobile-menu.is-open {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.mobile-menu-btn-close {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  transition: box-shadow .25s linear;
  position: absolute;
  top: 45px;
  right: 20px;
}

.modal-close-btn-icon {
  fill: #ec7f00;
}

.mobile-menu-btn-close:hover, .mobile-menu-btn-close:focus {
  box-shadow: 0 0 0 6px rgba(236, 126, 0, .325);
}

.mobile-menu-list {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 30px;
  padding: 100px 25px;
  display: flex;
}

.mobile-menu-item {
  color: #fff;
  font-size: 36px;
}

.mob-contacts {
  justify-content: center;
  gap: 24px;
  margin-top: 30px;
  padding-bottom: 30px;
  display: flex;
}

.btn-mob {
  width: 200px;
  height: 50px;
  color: #fff;
  background: #ec7f00;
  border-radius: 20px;
  flex-shrink: 0;
  margin: 0 auto;
  font-family: Montserrat;
  font-size: 18px;
  display: block;
}

@media screen and (min-width: 768px) {
  .logo {
    font-size: 68px;
  }

  .mobile-menu-btn {
    display: none;
  }

  .mobile-menu {
    width: 0;
    display: none;
  }

  .btn {
    width: 200px;
    height: 50px;
    color: #fff;
    background: #ec7f00;
    border-radius: 20px;
    flex-shrink: 0;
    font-family: Montserrat;
    font-size: 18px;
    display: block;
  }

  .btn-submit {
    position: absolute;
    top: 40px;
    right: 20px;
  }

  .navigation {
    margin-top: 28px;
    padding: 10px 20px;
  }

  .navigation-list {
    color: #fff;
    justify-content: center;
    gap: 39px;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .btn {
    width: 280px;
    height: 60px;
    font-size: 20px;
  }

  .btn-submit {
    position: absolute;
    top: 40px;
    right: 60px;
  }

  .navigation {
    margin-top: 34px;
  }

  .navigation-list {
    justify-content: center;
    gap: 59px;
    font-size: 24px;
    display: flex;
  }
}

.links {
  padding: 5px;
  position: relative;
}

.links:before, .links:after {
  content: "";
  height: 14px;
  width: 14px;
  opacity: 0;
  transition: all .35s;
  position: absolute;
}

.links:before {
  content: "";
  border-top: 3px solid #ec7f00;
  border-right: 3px solid #bd6500;
  top: 0;
  right: -5px;
  transform: translate(-100%, 50%);
}

.links:after {
  content: "";
  border-bottom: 3px solid #bd6500;
  border-left: 3px solid #ec7f00;
  bottom: 0;
  left: -5px;
  transform: translate(100%, -50%);
}

.links:hover:before, .links:hover:after {
  opacity: 1;
  transform: translate(0);
}

.links:hover {
  color: #ec7f00;
}

.hero {
  height: 450px;
  background-color: #313131;
  background-image: url("hero-br-img.635711b5.png");
  background-position: 100%;
  background-repeat: no-repeat;
}

.title-container {
  height: 450px;
  background-image: url("kisspng-stone-wall-brick-clip-art-get-brick-texture-png-pictures-5ab138695d4222 1 (Traced).a4254cc5.png");
  background-position: 0 -15px;
  background-repeat: no-repeat;
  padding: 40px 30px;
}

.hero-subtitle {
  color: #fff;
  width: 300px;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hero-title {
  color: #fff;
  letter-spacing: 2px;
  text-shadow: 1px 0 5px #a9a9a9;
  margin-top: 60px;
  margin-bottom: 30px;
  font-family: Impact;
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.slide-in-left {
  animation: slide-in-left 1.5s cubic-bezier(.25, .46, .45, .94) both;
}

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in-bottom {
  animation: slide-in-bottom 1.5s cubic-bezier(.25, .46, .45, .94) both;
}

@keyframes slide-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(1000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (min-width: 768px) {
  .hero-subtitle {
    font-size: 20px;
    font-style: normal;
  }

  .hero-title {
    font-size: 68px;
  }

  .title-container {
    padding: 40px 30px;
  }
}

@media screen and (min-width: 1440px) {
  .hero-subtitle {
    width: 325px;
  }

  .hero-title {
    width: 550px;
    margin-bottom: 30px;
  }

  .title-container {
    padding: 50px 60px;
  }

  .hero {
    height: 650px;
    background-color: #313131;
  }
}

.loc-title {
  color: #2d2d2d;
  margin-bottom: 14px;
  font-size: 32px;
  display: flex;
}

.contacts {
  padding: 20px 0;
}

.icon-loc {
  fill: #ec7f00;
}

.map {
  width: 300px;
  height: 250px;
  box-shadow: 10px 10px #ec7f00;
}

.contacts-wrap {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;
  padding-bottom: 44px;
  display: flex;
}

.contacts-list {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  font-size: 20px;
  display: flex;
}

.icon-contacts {
  fill: #ec7f00;
  margin-right: 8px;
}

.contacts-link {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

@media screen and (min-width: 768px) {
  .contacts-wrap {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0 30px;
    display: flex;
  }

  .map {
    width: 350px;
    height: 250px;
  }

  .contacts-list {
    gap: 14px;
    font-size: 22px;
  }
}

@media screen and (min-width: 1440px) {
  .map {
    width: 600px;
    height: 450px;
    box-shadow: 10px 10px 4px 2px #ec7e00;
  }

  .contacts-list {
    gap: 16px;
    font-size: 24px;
  }
}

.section-b {
  background-color: #e9e9e9;
}

.benefTit {
  color: #2d2d2d;
  margin-bottom: 20px;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.benefTxt {
  color: #2d2d2d;
  margin-bottom: 48px;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.benefList {
  flex-direction: column;
  gap: 40px;
  padding: 0 30px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .benefList {
    grid-gap: 40px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    justify-items: center;
    display: grid;
  }
}

@media screen and (min-width: 1440px) {
  .benefList {
    grid-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    justify-items: center;
    display: grid;
  }

  .benefItem {
    text-align: center;
    background-color: #e9e9e9;
    padding: 20px;
  }

  .benef-img {
    margin-top: 48px;
  }
}

.footer {
  background-color: #313131;
  background-image: url("kisspng-stone-wall-brick-clip-art-get-brick-texture-png-pictures-5ab138695d4222 1 (Traced).a4254cc5.png");
  background-position: 0 -15px;
  background-repeat: no-repeat;
}

.fo-container {
  padding: 14px 30px;
}

.logo-fot {
  font-size: 36px;
}

@media screen and (min-width: 768px) {
  .logo-fot {
    font-size: 38px;
  }
}

@media screen and (min-width: 1440px) {
  .logo-fot {
    font-size: 48px;
  }
}

.catalog-menu, .stellage-menu, .interier-menu, .bus-shells, .bar-menu, .hangers, .garden-bench, .barbeque, .swing {
  display: none;
}

.catalog-open {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 999;
  background-color: rgba(45, 45, 45, .89);
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
}

.catalog-menu-list {
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 30px 40px;
  display: flex;
}

.close-bench, .close-swing, .close-barbeque, .close-bar, .close-hangers, .close-shells, .close-table, .close-stellage, .close-inter {
  width: 40px;
  height: 40px;
  top: 20px;
  right: 30px;
}

.section-title-menu {
  padding-top: 60px;
}

.btn_up {
  width: 40px;
  height: 40px;
  opacity: 1;
  z-index: 1000;
  background-image: linear-gradient(to top, #ec7f00, #dcdcdc);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: box-shadow .25s linear, opacity .3s linear;
  display: inline-flex;
  position: fixed;
  bottom: 47px;
  right: 30px;
}

.btn_up:hover {
  box-shadow: 0 0 0 6px rgba(236, 126, 0, .325);
}

.hide-btn_up {
  opacity: 0;
  pointer-events: none;
}

.icon-btn_up {
  fill: #fff;
}

@media screen and (min-width: 1440px) {
  .btn_up {
    width: 45px;
    height: 45px;
    bottom: 50px;
    right: 50px;
  }
}

.backdrop {
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, .2);
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1), visibility .25s cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  top: 0;
  left: 0;
}

.backdrop.is-hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.submit-popup {
  height: auto;
  background-color: #e5e5e5;
  border-radius: 4px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .14), 0 1px 3px rgba(0, 0, 0, .12), 0 2px 1px rgba(0, 0, 0, .2);
}

.logo-side {
  display: none;
}

.submit-box {
  width: 308px;
  padding: 20px 30px;
}

.contact-form {
  width: 308px;
  border-radius: 4px;
}

.contact-form-input {
  width: 100%;
  height: 40px;
  border: 1px solid #a6a4a4;
  margin-bottom: 8px;
  padding-left: 18px;
  font-size: 14px;
  transition: border-color .25s linear;
}

.contact-form-input:focus {
  border-color: #2d2d2d;
  outline: none;
}

.policy-accept-txt {
  font-size: 12px;
}

.modal-send-btn {
  margin: 0 auto;
  display: block;
}

.checkbox-field {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .logo-side {
    background-color: #313131;
    background-image: url("kisspng-stone-wall-brick-clip-art-get-brick-texture-png-pictures-5ab138695d4222 1 (Traced).a4254cc5.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0 40px;
    display: block;
  }

  .logo-pop {
    text-align: center;
    margin-top: 88px;
    margin-bottom: 250px;
    font-family: Impact;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
  }

  .submit-box {
    width: 408px;
    padding: 20px 30px;
  }

  .contact-form {
    width: 368px;
    border-radius: 4px;
  }

  .contact-form-input {
    width: 100%;
    height: 40px;
    border: 1px solid #a6a4a4;
    margin-bottom: 8px;
    padding-left: 18px;
    font-size: 14px;
  }
}

.modal-descr-txt {
  text-align: center;
  letter-spacing: .02em;
  color: #f2a500;
  font-family: Impact, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
}

.descr-txt-dark {
  color: #2d2d2d;
}

.loc-txt-popup {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}

.form-side {
  position: relative;
}

.popup-close-btn {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  transition: box-shadow .25s linear;
  position: absolute;
  top: 15px;
  right: 15px;
}

.popup-close-btn:hover, .popup-close-btn:focus {
  box-shadow: 0 0 0 6px rgba(236, 126, 0, .325);
}

.contact-form-message {
  padding-top: 12px;
}

.policy {
  background-image: url("kisspng-stone-wall-brick-clip-art-get-brick-texture-png-pictures-5ab138695d4222 1 (Traced).a4254cc5.png");
  background-repeat: no-repeat;
  padding: 50px 100px;
}

.policy-txt {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 28px;
}

.policy-list {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.policy-dec {
  text-decoration: underline;
}



/*# sourceMappingURL=index.2ad4cd62.css.map */

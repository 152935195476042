/* --------------- СКИДАННЯ СТИЛІВ------------------ */
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style: none;
}

button {
  border: none;
  cursor: pointer;
}

/* img,
picture,
svg {
  display: block;
  max-width: 100%;
} */

a {
  text-decoration: none;
  color: inherit;
}
